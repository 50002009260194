import React, { Component } from 'react'
import {withRouter, Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip';
import LogoWhite from './Assets/BoldReactor.png';
import LogoRed from './Assets/BoldReactorRed.png'
import StartProject from './Assets/StartProject.png';
import StartProjectGrey from './Assets/StartProjectGey.png'
import BarWhite from './Assets/BarWhite.svg';
import BarBlue from './Assets/BarBlue.svg'
import GlobeWhite from './Assets/Globewhite.png';
import GlobeBlue from './Assets/Globeblue.png'
import AngleWhite from './Assets/AngleWhite.png';
import AngleBlue from './Assets/AngleBlue.png'
import GeoFlag from './Assets/geo-flag.png'
import UsFlag from './Assets/us-flag.png'
import MenuWhite from './Assets/MenuWhite.png'
import MenuBlue from './Assets/MenuBlue.png'
import './NavBar.css'
import {Link as LinkScroll} from 'react-scroll'

class NavBar extends Component {


    constructor (props){

        super(props);
        this.state ={
        
            scrolled:false,
            logo:LogoWhite,
            startProject:StartProject,
            bar: BarWhite,
            globe:GlobeWhite,
            angle:AngleWhite,
            menu:MenuWhite,
            listColor:'#ffffff',
            animatingLink:'nav-link nav-item',
            flag:GeoFlag,
            language: "GEO",
            menu:MenuWhite
            }
        this.switchingLanguage = ()=> {
            if (this.state.flag===GeoFlag) {
            this.setState({flag:UsFlag, language:"EN"})
            let current=props.history.location.pathname;
            if (current=='/'){
            props.history.push(`${current}geo`);
            current=props.history.location.pathname;
            const backToEnglish=props.history.location.pathname.substring(0,current.length-3);
            console.log(backToEnglish)
        }
            else props.history.push(`${current}/geo`)
}
        else {
            this.setState({flag:GeoFlag, language:"GEO"})
            }
}
        }
      

      /*   componentWillUnmount () {
            window.removeEventListener('scroll')
              
            } */
        componentDidMount (){
            window.addEventListener('scroll', ()=> {
            const isTop = window.scrollY<10;
            if (isTop !=true){
            this.setState({
                 
                scrolled:true,
                logo:LogoRed,
                startProject:StartProjectGrey,
                bar: BarBlue,
                globe:GlobeBlue,
                angle:AngleBlue,
                menu:MenuBlue,
                listColor:'#012A5E',
                animatingLink:'nav-item nav-link'
               
            })
            }
            else {
                this.setState({
                    scrolled:false,
                    logo:LogoWhite,
                    startProject:StartProject,
                    bar: BarWhite,
                    globe:GlobeWhite,
                    angle:AngleWhite,
                    menu:MenuWhite,
                    listColor:'#ffffff',
                    animatingLink:'nav-item nav-link'
                   
                })
            }
            })
            
            }

            
           
        render() {
           
           

       return (
            <div className={this.state.scrolled? 'NavBar Scrolled row':'NavBar row'}>
            <div className='col-xl-12'>
           
            
            <nav class="navbar navbar-expand-xl navbar-light">
            <div className='col-xl-2'></div>
            <Link class="navbar-brand hvr-bounce-in" to="/" style={{marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-14px':null}}><img className ='Logo' src={this.state.logo}/></Link>
            <div className='d-none d-xl-block' style={{width:"200px"}}></div>
            
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span class="" ><img src={this.state.menu} style={{height:'40px'}}/></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav">
            
            <Link to='/services' className={this.state.animatingLink} style={{color: this.state.listColor, marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-5px':'4px'}} >Services</Link>
             <Link class={this.state.animatingLink} to="/discover" style={{color: this.state.listColor, minWidth:'230px', textAlign: 'center', marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-5px':'4px'}}>Discover Reactor</Link>
            <LinkScroll class="nav-item nav-link StartProject"   to='Footer' smooth={true} duration={1000} style={{color: this.state.listColor,marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '0px':null }}><img src={this.state.startProject}/></LinkScroll>
                <Link class="nav-item nav-link Bars" to='/all' style={{color: this.state.listColor, marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-8px':null}} ><img src={this.state.bar} style={{maxWidth:'33px' }}/><img src={this.state.bar} style={{maxWidth:'33px'}}/><img src={this.state.bar} style={{maxWidth:'33px'}}/></Link>
                <a class="nav-item nav-link GlobeAngle"   style={{color: this.state.listColor, marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-13px':null}}  ><img className='Globe' src={this.state.globe} data-tip=''/><img className='Angle' src={this.state.angle} /></a>
                </div>
            </div>
          </nav> 
          
          
          
          </div>
<ReactTooltip className='extraClass' clickable={true} delayHide={1000} effect='solid' backgroundColor='#012A5E' > <div class="tooltiptext" style={{display: this.state.display}} onClick={this.switchingLanguage}><img src={this.state.flag} /><div className="Language">{this.state.language}</div></div></ReactTooltip>
          </div>
        )
    }
}
export default withRouter(NavBar)