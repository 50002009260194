import React, { Component } from 'react'
import {withRouter, Link} from 'react-router-dom'
import LogoRed from './Assets/BoldReactorRed.png'
import StartProjectGrey from './Assets/StartProjectGey.png'
import BarBlue from './Assets/BarBlue.svg'
import GlobeBlue from './Assets/Globeblue.png'
import AngleBlue from './Assets/AngleBlue.png'
import GeoFlag from './Assets/geo-flag.png'
import UsFlag from './Assets/us-flag.png'
import MenuWhite from './Assets/MenuWhite.png'
import MenuBlue from './Assets/MenuBlue.png'
import ReactTooltip from 'react-tooltip';
import './NavBar.css'
import {Link as LinkScroll} from 'react-scroll'
class NewNavBar extends Component {
    constructor (props){

        super(props);
        this.state ={
        scrolled:false,
            logo:LogoRed,
            startProject:StartProjectGrey,
            bar: BarBlue,
            globe:GlobeBlue,
            angle:AngleBlue,
            menu:MenuWhite,
            listColor:'#012A5E',
            animatingLink:'nav-link nav-item',
            flag:GeoFlag,
            language: "GEO",
            menu:MenuBlue
            }

        this.switchingLanguage = ()=> {
            if (this.state.flag===GeoFlag) {
            this.setState({flag:UsFlag, language:"EN"})
            const current=props.history.location.pathname;
            if (current=='/')
            props.history.push(`${current}geo`)
            else props.history.push(`${current}/geo`)
            
        }
        else {
            this.setState({flag:GeoFlag, language:"GEO"})
            }
}
        }

       componentDidMount (){
            window.addEventListener('scroll', ()=> {
            const isTop = window.scrollY<-110;
            if (isTop !=true){
            this.setState({
                 scrolled:true,
                logo:LogoRed,
                startProject:StartProjectGrey,
                bar: BarBlue,
                globe:GlobeBlue,
                angle:AngleBlue,
                menu:MenuBlue,
                listColor:'#012A5E',
                animatingLink:'nav-item nav-link'
               })
            
            }
            else {
                this.setState({
                    scrolled:true,
                    logo:LogoRed,
                    startProject:StartProjectGrey,
                    bar: BarBlue,
                    globe:GlobeBlue,
                    angle:AngleBlue,
                    menu:MenuBlue,
                    listColor:'#012A5E',
                    animatingLink:'nav-item nav-link'
                   })
            }
})
}
           render() {
        return (
            <div className='NavBar Scrolled row'>
            <div className='col-xl-12'>
            <nav class="navbar navbar-expand-xl navbar-light">
            <div className='col-xl-2'></div>
            <Link class="navbar-brand hvr-bounce-in" to="/"><img className ='Logo' src={this.state.logo} style={{marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-17px':null}}/></Link>
            <div className='d-none d-xl-block' style={{width:"200px"}}></div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span class="" ><img src={this.state.menu} style={{height:'40px'}}/></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup" >
              <div className="navbar-nav">
             <Link className={this.state.animatingLink}   to="/services" style={{color: this.state.listColor, marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-6px':'4px'}} >Services</Link>
             <Link class={this.state.animatingLink} to="/discover" style={{color: this.state.listColor, minWidth:'240px', textAlign:'center',  marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-6px':'4px'}}>Discover Reactor</Link>
                <LinkScroll class="nav-item nav-link StartProject" to="Footer" smooth={true} duration={1000} style={{color: this.state.listColor, marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-1px':null}}><img src={this.state.startProject}/></LinkScroll>
                <Link class="nav-item nav-link Bars" to='/all' style={{color: this.state.listColor, marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-8px':null}} ><img src={this.state.bar} style={{maxWidth:'33px'}}/><img src={this.state.bar} style={{maxWidth:'33px'}}/><img src={this.state.bar} style={{maxWidth:'33px'}}/></Link>
                <a class="nav-item nav-link GlobeAngle"  style={{color: this.state.listColor,marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-12px':null }} ><img className='Globe' src={this.state.globe} data-tip=''/><img className='Angle' src={this.state.angle} /></a>
                </div>
            </div>
          </nav> 
          </div>
          <ReactTooltip className='extraClass' clickable={true} delayHide={1000} effect='solid' backgroundColor='#012A5E' > <div class="tooltiptext" style={{display: this.state.display}} onClick={this.switchingLanguage}><img src={this.state.flag} /><div className="Language">{this.state.language}</div></div></ReactTooltip>
            </div>
        )
    }
}
export default withRouter(NewNavBar);