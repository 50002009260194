import React, {useEffect} from 'react';
import NewNavBar from './NewNavBar'
import './Services.css'
import ipad from  './Assets/ipad.png'
import Laptop from './Assets/laptop.jpg'
import Footer from './Footer'
import ReactGa from 'react-ga'
import {Helmet} from 'react-helmet'
import {Link} from 'react-scroll'


       

const Services = () => {
    ReactGa.initialize('UA-176397340-1');
    ReactGa.pageview('/services') 

    const scrollingDown =()=> {

        window.scrollBy(0, 780)
    }
    
    return (
        <div className='Services container-fluid'>
        <Helmet>
       <title>Services of Reactor</title>
       <meta name="description" content="Reactor provides web-development services using React & Wordpress"/>
       <meta name="keywords" content="HTML, CSS, JavaScript, React, Front-end development, Wordpress, Elementor, WP Bakery, Unison, Woocommerce"/>
       <meta name="author" content="Niko Mchedlishvili"/>
    </Helmet>
        <NewNavBar/>
        <div className='row'>
        <div className='BigIpad col-xl-6'>
        <img src={ipad}/>
        </div>
        <div className='Ecommerce col-xl-6'>
        <p>Web Apps that Attract</p>
        <p>People Everywhere </p>
        <p>Everytime</p>
        <p>We create web applications that stand out from others</p>
        <p> and attract your clients and friends</p>
        </div>
        </div>
        <div className='row'>
        <div className='CompleteEcommerce col-xl-12'>
        <p> Complete service to develope applications for your</p>
        <p>company to drive traffic and conversions</p>
        <p>We develop an easy-to-use and feature-rich portal for you, that not only generate</p>
        <p>more sales but also help you strengthen your brand image</p>
        </div>
        </div>
        <div className='Strategies row'>
        <div className='col-md-6'>
        <p>Strategy</p>
        <p>Development</p>
        </div>
        <div className='col-md-6'>
        <p>Custom Cart</p>
        <p>For your store</p>
        </div> 
        </div>
        <div className='SecondDeck row'>
        <div className='Pic'>
        <div>
        <img src={Laptop}/>
        </div>
        <div className='Offsite col-lg'>
        <p>Off-Site</p>
        <p>Management</p>
        </div>
        </div>
        <div className="SecondOne col-xl">
        <div className='row'>
        <div className='col-xl-4 ThirdParty'>
        <p>Third-Party</p>
        <p>Integration</p>
        </div>
        <div className='col-xl-4 col-lg-6 col-md-6 eStore'>
        <p>e-Store</p>
        <p>Customization</p>
        </div>
        <div className='col-xl-4 col-lg-6 col-md-6  Content'>
        <p>Content</p>
<p>Management System</p>
        </div>
        </div>
<div className='row'>
        <div className='col-lg-6 col-md-6 d-sm-none d-none d-md-block Payment'>
        <p>Payment</p>
        <p>Gateways</p>
        </div>
        <div className='col-lg-6 col-md-6 Marketing'>
        <p>Marketing</p>
<p>Automation</p>
        </div>
       </div>      
 </div>
 </div>
<div className="What">
<button><Link to='Footer' style={{color:"white", textDecoration:'none'}}>Get Advise</Link></button>
</div>
<div className='Complex row'>
<div className="Fuel col-xl-6">
</div>
<div className='col-xl-6 NoMatter'>
<p>
No matter How Complex Is
</p>
<p>Our Client's Application</p>
<p> We will take care at any stage of development process
begginning with consulting stage, up to choosing place for deployment.
From simple order processing to facilitating payments around the world, 
our digital e-commerce solutions help you provide seamless 
hopping experiences to your customers.</p>
</div>
</div>
<Footer/>
         </div>
    );
};

export default Services;