import React, { Component } from 'react'
import './Logos.css'
import ReactLogo from './Assets/ReactLogo.png'
import FacebookLogo from './Assets/facebookLogo.png'
import InstagramLogo from './Assets/instagramLogo.png'
import WhatsAppLogo from './Assets/whatsappLogo.png'
import NytimesLogo from './Assets/nytimesLogo.png'
import NetflixLogo from './Assets/netflixLogo.png'
import YahooLogo from './Assets/yahooLogo.png'
import CodAcademyLogo from './Assets/codecademyLogo.png'
import DropboxLogo from './Assets/DropboxLogo.png'
import Fade from 'react-reveal/Fade';

export default class Logos extends Component {

render() {
        return (
            <div className='Logos'>
            <div className='row'>
            <div className='WhyReact col-sm-12'>
            <p style={{fontFamily:'NinoBold', fontSize:'40px'}}>რატომ ირჩევთ React-ს?</p>
            <p style={{fontFamily:'NinoCaps', fontSize:'30px'}}>რომელი ინტერნეტ-გიგანტები იყენებენ React-ს?</p>
            </div>
            </div>
            <div className='d-none d-xl-block'>
            <div className='row no-gutters CompanyLogos ' >
            <div className='col-lg-4'>
           
            <div className='FacebookLogo'>
            <Fade left>
            <img src={FacebookLogo}/>
            </Fade>
            </div>
            
            
            <div className='InstagramLogo'>
            <Fade left>
            <img src={InstagramLogo}/>
            </Fade>
            </div>
            
            
            <div className="WhatsAppLogo">
            <Fade left>
            <img src={WhatsAppLogo}/>
            </Fade>
            </div>
            
            </div>
            <div className='col-lg-4 ReactLogo'>
            <Fade>
            <img src={ReactLogo}/>
            </Fade>
            </div>
            <div className='col-lg-4'>
            <div className="NetflixLogo">
            <Fade right>
            <img src={NetflixLogo}/>
            </Fade>
            </div>
            <div className='YahooLogo'>
            <Fade right>
            <img src={YahooLogo}/>
            </Fade>
            </div>
            <div className='NytimesLogo'>
            <Fade right>
            <img src={NytimesLogo}/>
            </Fade>
            </div>
            </div>
            
            </div>
            </div>


               <div className='CompanyLogosMini row d-xl-none'>
               <div className='col-lg-12 ml-lg-3 LogosFirstMini row'>
              <div className='col-lg-3 col-md-6'>
               <img className='FacebookMini' src={FacebookLogo} />
               </div>
               <div className='col-lg-3 col-md-6'>
               <img className='InstagramMini' src={InstagramLogo}/>
               </div>
               <div className='col-lg-3 col-md-6'>
               <img className='WhatsappMini' src={WhatsAppLogo}  />
               </div>
               <div className='col-lg-3 col-md-6'>
               <img className='NytimesMini' src={NytimesLogo}/>
               </div>
               </div>
              <div className='col-lg-12 LogosLastMini row'>
              <div className='col-lg-3 col-md-6'>
               <img className='NetflixMini' src={NetflixLogo}/>
               </div>
               <div className='col-lg-3 col-md-6'>
               <img className='YahooMini' src={YahooLogo}/>
               </div>
               <div className='col-lg-3 col-md-6'>
               <img className='CodeacademyMini' src={CodAcademyLogo}/>
               </div>
               <div className='col-lg-3 col-md-6'>
               <img className='DropboxMini' src={DropboxLogo}/>
               </div>
               </div>
               </div>
           




</div>



        )
    }
}
