import React, {useState} from 'react';
import emailjs from 'emailjs-com';


export default function ContactUs() {

  const[Values,  setValues]=useState({Name:'', Email:'', Message:'', Button:"Submit"})
  const clearValues= () =>{
    setValues({Name:"", Email:'', Message:'', Button:'Thanks for message', Style:{width:'300px'}})
  }
  

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'reactor', e.target, 'user_0fK42wK9O0ZZcEakvEkgU')
      .then((result) => {
         clearValues()
        
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
    <div className='col-xl-9'>
    <form className="contact-form" onSubmit={sendEmail}>
    <div className='row'>
      <div className='col-xl-6 DivName'>
     <input className="Name" type="text" name="user_name" placeholder="Your Name" value={Values.Name} onChange={(e)=>setValues({Name:e.target.value})}/>
     </div>
     <div className='col-xl-6 DivName'>
     <input className='Email' type="email" name="user_email" placeholder='Your Email' value={Values.Email} onChange={(e)=>setValues({Email:e.target.value})}/>
      </div>
      </div>
      <div className='row'>
      <div className='col-xl-12 DivTextarea'>
     <input type='text' className='Textarea' name="message" placeholder='Type Your Message Here' value={Values.Message} onChange={(e)=>setValues({Message:e.target.value})}/>
     </div> 
     </div>
     <input className="Sending" type='submit' style={Values.Style} value={Values.Button}/>
    </form>
    </div>
  );
}
