import React from 'react';
import './Footer.css'
import EmailJSGeo from './EmailJSGeo'


const FooterGeo = () => {
    return (
        <div className='Footer row' id="Footer" style={{height: (navigator.appVersion.indexOf('Win')) !=-1? "320px": null}}>
        <div className='RedLine col-xl-12'>
        <div className='row'>
        <div className='ContactForm col-xl-8'>
        <div className='row'>
        <div className="Know col-xl-12" style={{fontFamily:'NinoCaps', fontSize:'32px'}}><p>გაიგეთ მეტი!</p></div>
        </div>
        <div className='row'>
        <div className='col-xl-3'>
        <p className="SendMessage" style={{fontFamily:'NinoCaps'}}>მოგვწერეთ:</p>
        </div>
        <EmailJSGeo/>
        </div>
        </div>
        <div className='WhiteLine'></div>
        <div className='Address col-xl col-md-12' >
        <p  style={{fontFamily:'NinoCaps', fontSize:'32px'}}>ოფისი</p>
        <p><b>contact@reactor.ge</b></p>
        <p>+995 595773399</p>
        <p style={{fontFamily:"Nateli"}}>საქართველო, თბილისი 0112</p>
        <p>ჩუბინაშვილის 68</p>
        </div>
        </div>
        </div>
        </div>
    );
};

export default FooterGeo;