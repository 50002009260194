import React from 'react';
import './Typing.css'
import RedBar from './RedBar'
import Bounce from 'react-reveal/Bounce';
import CombinedShape from './Assets/CombinedShape.png'
import Oval from './Assets/Oval.svg'

const Typing = () => {
    return (
    
        <div className='Typing row'>
       
           <div className="col-lg-2 RedBarCol">
           <RedBar/>
           </div>
           <div className='col-lg-10 TextTyping'>
           <Bounce left cascade>
           <p>Everything you need in one place</p>
           <p>Web Solutions in React & Wordpress</p>
           <p>E-commerce, web design, development,</p>
           <p>consulting, managed services</p>
           </Bounce>
           <a href='#MovingCube'> <img src={CombinedShape}/></a>
           <img className='Oval' src={Oval}/>
           </div>
          </div>
        
    );
};

export default Typing;