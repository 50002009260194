import React, { Component } from 'react'
import './MovingCube.css'




export default class MovingCube extends Component {
    

    render() {

        return (
            <div className='MovingCube row' id='MovingCube'>
            <div className="ConsultingContainer  col-xl-5 col-lg-5">
           <p>CONSULTING</p>
         <p >Succesfull Collaboration from Idea
Inception to Project Launch</p>
            </div>
            <div className='col-xl-3 Moving'>
            </div>
            <div className='WebContainer col-xl col-lg col-md col-sm' >
            <p >WEB</p>
            <p>Design.Develop.Deploy<br/>
   </p>
            </div>
            </div>
        )
    }
}
