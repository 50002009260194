import React, { Component } from 'react'
import RedBar from './RedBar'
import Flip from 'react-reveal/Flip';
import './Typing.css'
import './Discover.css'
import reactLogo from './Assets/Stack/reactLogo.png'
import nodeLogo from './Assets/Stack/nodeLogo.webp'
import mongoDB from './Assets/Stack/mongodbLogo.png'
import wordpress from './Assets/Stack/WordPressLogo.png'
import  woocommerce from './Assets/Stack/woocommerceLogo.png'
import elementor from './Assets/Stack/elementorLogo.png'
import FooterGeo from './FooterGeo'



const TypingDiscover = () => {
    return (
        <div className="Typing row HeaderNew">
        <RedBar/>
        <div className='TextTyping col-lg-10 TextTypingDiscover'>
       <p style={{fontFamily:'NinoCaps'}}>ჩვენს შესახებ:</p>
        <p style={{fontFamily:'NinoBold'}}>განსხვავებული. კლიენტზე ორიენტირებული. </p>
        <p style={{fontFamily:'NinoCaps'}}>სრულყოფილი გადაწყვეტილებები</p>
      </div>
        <div className='row'>
        <div className='Overview OverviewGeo col-xl-12'>
        <p style={{fontFamily:'NinoBold'}}>მიმოხილვა</p>
        <p style={{fontFamily:'NinoCaps'}}>ჩვენი პრინციპია ხელი შევუწყოთ კლიენტებს იდეების გენერირებასა და განხორციელებაში, რაც მათი ბიზნეს-ამოცანების გადაწყვეტის კარგი საწინდარია. ჩვენ განვსხვავდებით კრეატიულობით, საქმის ცოდნით და გამოცდილებით იმ ტექნოლოგიებში, რომლებსაც თითოელ პროექტში ვიყენებთ. ჩვენ ასევე ძალიან ვაფასებთ ურთერთობების მნიშვნელობას</p>
        </div>
        </div>
     <div className='container-fluid'>
     <div className='row'>
     <div className='Leadership col-xl-12'>
     <div className='row'>
     <div className='col-md-7 LeadershipText'>
     <p style={{fontFamily:'NinoBold'}}>გუნდი</p>
     <p style={{fontFamily:'NinoCaps'}}>ჩვენ ვმუშაობთ დეველოპერებთან, ვისაც უყვართ ინოვაციები, იყენებენ იმ ტექნოლოგიებს, რომელიც თქვენს ბიზნესს სჭირდება! თქვენ ჩვენთან ერთად შეგიძლიათ შეიმუშაოთ სტრატეგიები და გადაწყვეტილებები ბიზნესისათვის, ჩვენთან ერთად ჩაუყაროთ საფუძველი თქვენს და ჩვენს წარმატებას!</p>
     </div>
     </div>
     </div>
     </div>
<div className='row'>
     <div className='col-xl-12 Technology'>
     <p style={{fontFamily:'NinoBold'}}>ჩვენ ვიყენებთ ტექნოლოგიებს</p>
     </div>
     </div>
<div className='row Stack'>
     <div className='col-xl-4'>
    <Flip  top>
     <img src={nodeLogo}/>
</Flip>
     </div>
     <div className='col-xl-4'>
     <Flip top>
<img src={reactLogo}/>
</Flip>
     </div>
     <div className='col-xl-4'>
     <Flip top>
     <img src={mongoDB}/>
     </Flip>
     </div>
     </div>
<div className='row Wordpress'>
     <div className='col-xl-4'>
    <Flip  bottom>
     <img src={wordpress}/>
</Flip>
     </div>
     <div className='col-xl-4'>
     <Flip bottom>
<img src={woocommerce}/>
</Flip>
     </div>
     <div className='col-xl-4'>
     <Flip bottom>
     <img src={elementor}/>
     </Flip>
     </div>
     </div>
     <div className='row Process'>
     <div className='col-lg-5'>
     <p style={{fontFamily:'NinoBold'}}>პროცესი</p>
     <p style={{fontFamily:'NinoCaps'}} >-- კონსალტინგი</p>
     <p style={{fontFamily:'NinoCaps'}}>დიზაინი</p>
     <p style={{fontFamily:'NinoCaps'}} >დეველოპმენტი</p>
     <p style={{fontFamily:'NinoCaps'}} >მხარდაჭერა</p>
     </div>
     <div className='col-lg-7'>
     <p  style={{fontFamily:'NinoBold'}}>ერთი ფანჯრის პრინციპი</p>
     <p style={{fontFamily:'NinoBold'}} >კლიენტი</p>
     <p style={{fontFamily:'NinoCaps'}} >ჩვენ ვიწყებთ თქვენი მოთხოვნების გააზრებით და კომუნიკაციას ვაგრძელებთ დისკუსიის შესაბამისად</p>
</div>
     </div>
<FooterGeo/>
</div>
       </div>
    );
};

export default TypingDiscover;