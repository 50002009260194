import React from 'react';
import MainPage from './MainPage'

function App() {
  
  return (
    <div className="App">
    <MainPage/>
    </div>
  );
}

export default App;
