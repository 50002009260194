import React, {useState} from 'react';
import emailjs from 'emailjs-com';


export default function ContactUsGeo() {

  const[ValuesGeo,  setValuesGeo]=useState({Name:'', Email:'', Message:'', ButtonGeo:'გაგზავნა'})
  function clearValues () {
    setValuesGeo({Name:"", Email:'', Message:'', ButtonGeo:"გმადლობთ! გაგზავნილია!", Style:{width:'300px'}})
  }

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'reactor', e.target, 'user_0fK42wK9O0ZZcEakvEkgU')
      .then((result) => {
         clearValues()
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
    <div className='col-xl-9'>
    <form className="contact-form" onSubmit={sendEmail}>
    <div className='row'>
      <div className='col-xl-6 DivName'>
     <input className="Name" type="text" name="user_name" placeholder="თქვენი სახელი" value={ValuesGeo.Name} onChange={(e)=>setValuesGeo({Name:e.target.value})}/>
     </div>
     <div className='col-xl-6 DivName'>
     <input className='Email' type="email" name="user_email" placeholder='ელ.ფოსტა' value={ValuesGeo.Email} onChange={(e)=>setValuesGeo({Email:e.target.value})}/>
      </div>
      </div>
      <div className='row'>
      <div className='col-xl-12 DivTextarea'>
     <input type='text' className='Textarea' name="message" placeholder='თქვენი გზავნილი' value={ValuesGeo.Message} onChange={(e)=>setValuesGeo({Message:e.target.value})}/>
     </div> 
     </div>
     <input className='SendingGeo' type='submit' style={ValuesGeo.Style} value={ValuesGeo.ButtonGeo} />
    </form>
    </div>
  );
}
