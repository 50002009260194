import React, { Component } from 'react'

import {withRouter, Link} from 'react-router-dom'
import LogoRed from './Assets/BoldReactorRed.png'
import StartProjectGreyGeo from './Assets/StartProjectGreyGeo.png'
import BarBlue from './Assets/BarBlue.svg'
import GlobeBlue from './Assets/Globeblue.png'
import AngleBlue from './Assets/AngleBlue.png'
import GeoFlag from './Assets/geo-flag.png'
import UsFlag from './Assets/us-flag.png'
import MenuWhite from './Assets/MenuWhite.png'
import MenuBlue from './Assets/MenuBlue.png'
import ReactTooltip from 'react-tooltip';
import './NavBar.css'
import {Link as LinkScroll} from 'react-scroll'
class NewNavBar extends Component {
    constructor (props){

        super(props);
        this.state ={
        
            scrolled:false,
            logo:LogoRed,
            startProject:StartProjectGreyGeo,
            bar: BarBlue,
            globe:GlobeBlue,
            angle:AngleBlue,
            menu:MenuWhite,
            listColor:'#012A5E',
            animatingLink:'nav-link nav-item',
            flag:UsFlag,
            language: "ENG",
            menu:MenuBlue
            }

        this.switchingLanguage = ()=> {
            if (this.state.flag===UsFlag) {
                this.setState({flag:GeoFlag, language:"GEO"})
                const current=props.history.location.pathname;
                const backToEnglish=props.history.location.pathname.substring(0,current.length-4);    
                if (current=='/geo') {
                props.history.push(`${backToEnglish}`);}
               else props.history.push(`${backToEnglish}`)
                
            }
            else {
                this.setState({flag:GeoFlag, language:"GEO"})
                }
    }
            }
    
           
        componentDidMount (){
            window.addEventListener('scroll', ()=> {
            const isTop = window.scrollY<-110;
            if (isTop !=true){
            this.setState({
                 
                scrolled:true,
                logo:LogoRed,
                startProject:StartProjectGreyGeo,
                bar: BarBlue,
                globe:GlobeBlue,
                angle:AngleBlue,
                menu:MenuBlue,
                listColor:'#012A5E',
                animatingLink:'nav-item nav-link'
               })
            
            }
            else {
                this.setState({
                    scrolled:true,
                    logo:LogoRed,
                    startProject:StartProjectGreyGeo,
                    bar: BarBlue,
                    globe:GlobeBlue,
                    angle:AngleBlue,
                    menu:MenuBlue,
                    listColor:'#012A5E',
                    animatingLink:'nav-item nav-link'
                   })
            }
})
}
           render() {
        return (
            <div className='NavBar Scrolled row'>
            <div className='col-xl-12'>
           
            <nav class="navbar navbar-expand-xl navbar-light">
            <div className='col-xl-2'></div>
            <Link class="navbar-brand hvr-bounce-in" to="/geo"><img className ='Logo' src={this.state.logo} style={{marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-21px':null}}/></Link>
            <div className='d-none d-xl-block' style={{width:"200px"}}></div>
            
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" style={{marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-10px':null}}>
              <span class="" ><img src={this.state.menu} style={{height:'40px'}}/></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup" >
              <div className="navbar-nav">
             <Link className={this.state.animatingLink}   to="/services/geo" style={{color: this.state.listColor,transform:'translateY(4px)', fontSize:'16px', fontFamily:'NinoBold',marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-3px':'8px' }} >სერვისები</Link>
             <Link class={this.state.animatingLink} to="/discover/geo" style={{color: this.state.listColor, minWidth:'200px', fontFamily:'NinoBold', transform:'translateY(4px)', fontSize:'16px',marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-3px':'8px' }}>აღმოაჩინე 'რეაქტორი'</Link>
                <LinkScroll class="nav-item nav-link StartProject" to="Footer" smooth={true} duration={1000} style={{color: this.state.listColor, marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-2px':null}}><img src={this.state.startProject}/></LinkScroll>
                <Link class="nav-item nav-link Bars" to='/all/geo' style={{color: this.state.listColor}} ><img src={this.state.bar} style={{maxWidth:'33px', marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-2px':null}}/><img src={this.state.bar} style={{maxWidth:'33px'}}/><img src={this.state.bar} style={{maxWidth:'33px'}}/></Link>
                <a class="nav-item nav-link GlobeAngle"  style={{color: this.state.listColor, marginTop:(navigator.appVersion.indexOf('Win')) !=-1? '-14px':null}} ><img className='Globe' src={this.state.globe} data-tip=''/><img className='Angle' src={this.state.angle} /></a>
                </div>
            </div>
          </nav> 
          </div>
          <ReactTooltip className='extraClass' clickable={true} delayHide={1000} effect='solid' backgroundColor='#012A5E' > <div class="tooltiptext" style={{display: this.state.display}} onClick={this.switchingLanguage}><img src={this.state.flag} /><div className="Language">{this.state.language}</div></div></ReactTooltip>
            </div>
        )
    }
}
export default withRouter(NewNavBar);