import React from 'react';
import './MainPage.css'
import NavBarGeo from "./NavBarGeo"
import TypingGeo from './TypingGeo'
import MeetGeo from './MeetGeo'
import LogosGeo from './LogosGeo'
import MovingCubeGeo from './MovingCubeGeo'
import FooterGeo from './FooterGeo'
import ReactGa from 'react-ga'
import {Helmet} from 'react-helmet'

const MainPageGeo = () => {
    ReactGa.initialize('UA-176397340-1');
    ReactGa.pageview('/geo')

    return (
       <div className="MainPage container-fluid">
       <Helmet>
       <title>რეაქტორი</title>
       <meta name="description" content="რეაქტორი გთავაზობთ ვებ-დეველოპმენტტს React-ის & Wordpress-ის გამოყენებით"/>
       <meta name="keywords" content="HTML, CSS, JavaScript, React, ვები, ვებ-საიტების დამზადება, ელექტრონული კომერცია, მარკეტინგი, Wordpress, Elementor, WP Bakery, Unison, Woocommerce"/>
       <meta name="author" content="ნიკო მჭედლიშვილი"/>
       </Helmet>
       <NavBarGeo/>
       <TypingGeo/>
       <MeetGeo/>
       <LogosGeo/>
       <MovingCubeGeo/>
       <FooterGeo/>
      


       </div>
    )
};

export default MainPageGeo;