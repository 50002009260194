import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import * as serviceWorker from './serviceWorker';
import {Route, HashRouter as Router, Switch} from "react-router-dom";
import Services from './Services'
import Discover from './Discover' 
import MainPageGeo from './MainPageGeo'
import DiscoverGeo from './DiscoverGeo'
import  ServicesGeo from './ServicesGeo'
import AllGeo from './AllGeo'


import All from './All'
import NotFound from './NotFound'


ReactDOM.render(
  <React.StrictMode>
  <Router>
  <Switch>
  <Route exact path='/' render={App}/>
  <Route exact path='/geo' render={MainPageGeo}/>
  <Route exact path='/services' render={Services}/>
  <Route exact path='/services/geo' render={ServicesGeo}/>
  <Route exact path='/discover' render={Discover}/>
  <Route exact path='/discover/geo' render={DiscoverGeo}/>
  <Route exact path='/all' render={All}/>
  <Route exact path='/all/geo' render={AllGeo}/>
  
  <Route path='*' render={NotFound}/>
</Switch>
  </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
