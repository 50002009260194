import React, { Component } from 'react'
import './Typing.css'


export default class RedBar extends Component {
    render() {
        return (
            <div className='RedBar' style={{top: (navigator.appVersion.indexOf('Win')) !=-1 ? "270px": null}}>
                </div>
        )
    }
}