import React, { Component } from 'react'
import './MovingCube.css'

export default class MovingCube extends Component {
    
    render() {
    return (
            <div className='MovingCube row' id='MovingCube'>
            <div className="ConsultingContainer  col-xl-5 col-lg-5">
           <p style={{fontFamily:'NinoBold'}}>კონსალტინგი</p>
         <p style={{fontFamily:'NinoCaps', fontSize:"17px"}} >წარმატებილი თანამშრომლობა იდეის აღმოცენებიდან პროექტის დაწყებამდე</p>
            </div>
            <div className='col-xl-3 MovingGeo'>
            </div>
            <div className='WebContainer col-xl col-lg col-md col-sm' >
            <p style={{fontFamily:'NinoBold'}}>ვები</p>
            <p style={{fontFamily:'NinoCaps', fontSize:'17px'}}>ბიზნესზე მორგებული გადაწყვეტილებები ინტერნეტში</p>
            </div>
            </div>
        )
    }
}
