import React from 'react';
import './MainPage.css'
import NavBar from "./NavBar"
import Typing from './Typing'
import Meet from './Meet'
import Logos from './Logos'
import MovingCube from './MovingCube'
import Footer from './Footer'
import ReactGa from 'react-ga'
import {Helmet} from 'react-helmet'



const MainPage = () => {
 
  
  
    ReactGa.initialize('UA-176397340-1');
  ReactGa.pageview('/')
    return (
       <div className="MainPage container-fluid">
       <Helmet>
       <title>Reactor</title>
       <meta name="description" content="Reactor provides web-development services using React & Wordpress"/>
       <meta name="keywords" content="HTML, CSS, JavaScript, React, Front-end development, Wordpress, Elementor, WP Bakery, Unison, Woocommerce"/>
       <meta name="author" content="Niko Mchedlishvili"/>
      </Helmet>
      
       <NavBar/>
      
       <Typing/>
       <Meet/>
       <Logos/>
       <MovingCube/>
     
       <Footer />
       
      </div>
    )
};

export default MainPage;