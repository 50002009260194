import React from 'react';
import NavBarGeo from './NavBarGeo'
import TypingDiscoverGeo from './TypingDiscoverGeo'
import './Discover.css'
import ReactGa from 'react-ga'
import {Helmet} from 'react-helmet'


const Discover = () => {
    ReactGa.initialize('UA-176397340-1');
  ReactGa.pageview('/discover/geo')
    return (
        <div className='Discover container-fluid'>

        <Helmet>
        <title>აღმოაჩინე რეაქტორი</title>
        <meta name="description" content="რეაქტორი გთავაზობთ ვებ-დეველოპმენტტს React-ის & Wordpress-ის გამოყენებით"/>
        <meta name="keywords" content="HTML, CSS, JavaScript, React, ვები, ვებ-საიტების დამზადება, ელექტრონული კომერცია, მარკეტინგი, Wordpress, Elementor, WP Bakery, Unison, Woocommerce"/>
        <meta name="author" content="ნიკო მჭედლიშვილი"/>
        </Helmet> 
           <NavBarGeo/>
           <TypingDiscoverGeo/>
          
        </div>
    );
};

export default Discover;