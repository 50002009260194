import React, { Component } from 'react'
import RedBar from './RedBar'
import Flip from 'react-reveal/Flip';
import './Typing.css'
import './Discover.css'
import reactLogo from './Assets/Stack/reactLogo.png'
import nodeLogo from './Assets/Stack/nodeLogo.webp'
import mongoDB from './Assets/Stack/mongodbLogo.png'
import wordpress from './Assets/Stack/WordPressLogo.png'
import  woocommerce from './Assets/Stack/woocommerceLogo.png'
import elementor from './Assets/Stack/elementorLogo.png'
import Footer from './Footer'

const TypingDiscover = () => {
    return (
        <div className="Typing row HeaderNew">
        <RedBar/>
        <div className='TextTyping col-lg-10 TextTypingDiscover'>
       <p>About Us</p>
        <p>Different. Client-oriented. Dedicated</p>
        <p>Full Solution</p>
      </div>
        <div className='row'>
        <div className='Overview col-xl-12'>
        <p>Overview</p>
        <p>Right from the year of launch, Reactor has been following the principle of building & implementing great ideas that empower the clients' business
        & enhance their lives through innovative enterprise solutions. Our team is differentiated by the imagination, knowledge, and experience across
        technologies that we bring to each project we handle. We understand the importance of nurturing relationships</p>
        </div>
        </div>
     <div className='container-fluid'>
     <div className='row'>
     <div className='Leadership col-xl-12'>
     <div className='row'>
     <div className='col-md-7 LeadershipText'>
     <p>Team</p>
     <p>We’re a group of hardworking souls, innovative thinkers and the counterparts of the Technology that drives you! Leading the development and strategies for application development and requisite insights on forthcoming approach. Grow with us for an ever-changing world of technology by co-creating breakthrough solutions through mobility, big data analytics and web!</p>
     </div>
     </div>
     </div>
     </div>
<div className='row'>
     <div className='col-xl-12 Technology'>
     <p>Technology We Use</p>
     </div>
     </div>
<div className='row Stack'>
     <div className='col-xl-4'>
    <Flip  top>
     <img src={nodeLogo}/>
</Flip>
     </div>
     <div className='col-xl-4'>
     <Flip top>
<img src={reactLogo}/>
</Flip>
     </div>
     <div className='col-xl-4'>
     <Flip top>
     <img src={mongoDB}/>
     </Flip>
     </div>
     </div>
<div className='row Wordpress'>
     <div className='col-xl-4'>
    <Flip  bottom>
     <img src={wordpress}/>
</Flip>
     </div>
     <div className='col-xl-4'>
     <Flip bottom>
<img src={woocommerce}/>

</Flip>
     </div>
     <div className='col-xl-4'>
     <Flip bottom>
     <img src={elementor}/>
     </Flip>
     </div>
     </div>
     <div className='row Process'>
     <div className='col-lg-5'>
     <p>Process</p>
     <p>-- Consulting</p>
     <p>Design</p>
     <p>Development</p>
     <p>Support</p>
     </div>
     <div className='col-lg-7'>
     <p>One Window Approach</p>
     <p>Client</p>
     <p>We start by understanding your requirement and communicate further as per the discussion</p>
</div>
     </div>
<Footer/>
</div>
       </div>
    );
};

export default TypingDiscover;