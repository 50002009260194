import React from 'react';
import NewNavBarGeo from './NewNavBarGeo'
import './Services.css'
import ipad from  './Assets/ipad.png'
import Laptop from './Assets/laptop.jpg'
import FooterGeo from './FooterGeo';
import ReactGa from 'react-ga'
import {Helmet} from 'react-helmet'
import {Link} from 'react-scroll'


const Services = () => {

    ReactGa.initialize('UA-176397340-1');
  ReactGa.pageview('/services/geo')
    const scrollingDown =()=> {

        window.scrollBy(0, 780)
    }

    return (
        <div className='Services container-fluid'>
<Helmet>
        <title>რეაქტორის სერვისები</title>
        <meta name="description" content="რეაქტორი გთავაზობთ ვებ-დეველოპმენტტს React-ის & Wordpress-ის გამოყენებით"/>
        <meta name="keywords" content="HTML, CSS, JavaScript, React, ვები, ვებ-საიტების დამზადება, ელექტრონული კომერცია, მარკეტინგი, Wordpress, Elementor, WP Bakery, Unison, Woocommerce"/>
        <meta name="author" content="ნიკო მჭედლიშვილი"/>
        </Helmet>
       <NewNavBarGeo/>
        <div className='row'>
        <div className='BigIpad col-xl-6'>
        <img src={ipad}/>
        </div>
        <div className='Ecommerce EcommerceGeo col-xl-6'>
        <p style={{fontFamily:'NinoCaps'}}>მიმზიდველი აპლიკაციები</p>
        <p style={{fontFamily:'NinoBold'}}>ყველასათვის, ყველგან</p>
        <p style={{fontFamily:"NinoBold"}}> და ყოველთვის</p>
        <p style={{fontFamily:'NinoCaps'}}>ჩვენ ვამზადებთ აპლიკაციებს, რომლებიც განსხვავებულია</p>
        <p style={{fontFamily:'NinoCaps'}}> და იზიდავენ თქვენც კლიენტებს და მეგობრებს</p>
        </div>
        </div>
        <div className='row'>
        <div className='CompleteEcommerce CompleteEcommerceGeo col-xl-12'>
        <p style={{fontFamily:'NinoBold'}}> სრულყოფილი სერვისი აპლიკაციების შესაქმენად</p>
        <p style={{fontFamily:'NinoBold'}}>და ვებ-ტრაფიკის გასაზრდელად </p>
        <p style={{fontFamily:'NinoCaps'}}>ჩვენ თქვენთვის ვამზადებთ ადვილად სამართავ და მრავალფუნქციურ პორტალებს, რაც მეტ გაყიდვებს აგენერირებს</p>
        <p style={{fontFamily:'NinoCaps'}}>და აძლიერებს თქვენი ბრენდის იმიჯს </p>
        </div>
        </div>
        <div className='Strategies StrategiesGeo row'>
        <div className='col-md-6'>
        <p style={{fontFamily:'NinoBold'}}>სტრატეგიის</p>
        <p style={{fontFamily:'NinoCaps'}}>შემუშავება</p>
        </div>
        <div className='col-md-6'>
        <p style={{fontFamily:'NinoBold'}}>კალათა</p>
        <p style={{fontFamily:'NinoCaps'}}>მაღაზიისათვის</p>
        </div> 
        </div>
        <div className='SecondDeck SecondDeckGeo row'>
        <div className='Pic'>
        <div>
        <img src={Laptop}/>
        </div>
        <div className='Offsite col-lg'>
        <p style={{fontFamily:'NinoBold', fontSize:'32px'}}>დისტანციური</p>
        <p style={{fontFamily:'NinoCaps', fontSize:'26px'}}>მენეჯმენტი</p>
        </div>
        </div>
        <div className="SecondOne SecondOneGeo col-xl">
        <div className='row'>
        <div className='col-xl-4 ThirdParty'>
        <p style={{fontFamily:'NinoBold'}}>სერვისების</p>
        <p style={{fontFamily:'NinoCaps'}}>ინტეგრაცია</p>
        </div>
        <div className='col-xl-4 col-lg-6 col-md-6 eStore'>
        <p style={{fontFamily:'NinoBold'}}>მაღაზიის</p>
        <p style={{fontFamily:'NinoCaps'}}>გამართვა</p>
        </div>
        <div className='col-xl-4 col-lg-6 col-md-6  Content'>
        <p style={{fontFamily:'NinoBold'}} >კონტენტის</p>
<p style={{fontFamily:'NinoCaps'}} >მენეჯმენტი</p>
        </div>
        </div>
<div className='row'>
        <div className='col-lg-6 col-md-6 d-sm-none d-none d-md-block Payment'>
        <p style={{fontFamily:'NinoBold'}} >გადახდის</p>
        <p style={{fontFamily:'NinoCaps'}} >არხები</p>
        </div>
        <div className='col-lg-6 col-md-6 Marketing'>
        <p style={{fontFamily:'NinoBold'}} >მარკეტინგის</p>
<p style={{fontFamily:'NinoCaps'}} >ავტომატიზაცია</p>
        </div>
       </div>      
 </div>
 </div>
<div className="What WhatGeo">
<button><Link to='Footer' style={{color:"white", textDecoration:'none', fontFamily:'NinoCaps', padding:"auto"}}>მიიღეთ რჩევა</Link></button>
</div>
<div className='Complex row'>
<div className="Fuel col-xl-6">
</div>
<div className='col-xl-6 NoMatter NoMatterGeo'>
<p style={{fontFamily:"NinoCaps", fontSize:'32px'}}>
არ აქვს მნიშვნელობა რამდენად კომპლექსურია
</p>
<p style={{fontFamily:'NinoBold', fontSize:'32px'}}>ჩვენი კლიენტის აპლიკაცია</p>
<p style={{fontFamily:'NinoCaps'}}> ჩვენ ვზრუნავთ დეველოპმენტის პროცესის ყველა ეტაპზე, დაწყებული კონსულტაციიდან, დამთავრებული ვებ-საიტის განთავსებით სერვერზე.
მომხმარებლის შეკვეთის დამუშავება და გადახდების მიღება მსოფლიოს ნებისმიერი წერტილიდან თქვენს კლიენტებს საუკეთესო მომსახურებს გაუწევს. </p>
</div>
</div>
<FooterGeo/>
         </div>
    );
};

export default Services;