import React from 'react';
import './Footer.css'
import EmailJS from './EmailJS'


const Footer = () => {
    return (
        <div className='Footer row' id="Footer" style={{height: (navigator.appVersion.indexOf('Win')) !=-1? "320px": null}}>
        <div className='RedLine col-xl-12'>
        <div className='row'>
       <div className='ContactForm col-xl-8'>
        <div className='row'>
        <div className="Know col-xl-12"><p>Know Us Better!</p></div>
        </div>
        <div className='row'>
        <div className='col-xl-3'>
        <p className="SendMessage">Send Us a Message:</p>
        </div>
        <EmailJS/>
        </div>
        </div>
        <div className='WhiteLine'></div>
        <div className='Address col-xl col-md-12' >
        <p>Office</p>
        <p><b>contact@reactor.ge</b></p>
        <p>+995 595773399</p>
        <p>68, Chubinashvili Str,</p>
        <p>Tbilisi 0112 Georgia</p>
        </div>
        </div>
        </div>
        </div>
    );
};

export default Footer;