import React from 'react';
import  './Meet.css'

const Meet = () => {
    return (
    <div className='row Meet no-gutters'>
    <div className="col-xl-5 d-flex align-items-center">
    <p>We Meet You,<br/>
    <span>consult, design web-site,<br/>
    develop and deploy</span></p>
    </div>
    <div ></div>
    <div className='col-xl-6 d-flex flex-row align-items-center'><h5>We use React for web development which is most demanded and advanced front-end framework at the moment. During the consultation process with our clients we explain advantages of React and its possiibilities. 
    If client needs cheeper and faster web solution, Wordpress comes into play. Wordpress is most popular traditional development tool. We favor Elementor, Elementor Pro and WP Bakery page builders when working with Wordpress.</h5></div>
    </div>)
}

export default Meet;