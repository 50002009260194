import React from 'react';
import  './Meet.css'

const MeetGeo = () => {
    return (
    <div className='row Meet'>
    <div className="col-xl-5">
    <p style={{fontFamily:'NinoBold', fontSize:'36px', marginTop:'50px'}}>ჩვენ გხვდებით,<br/>
    <span style={{fontFamily:'NinoCaps', fontSize:'18px'}}>გიწევთ კონსულტაციას, ვაკეთებთ<br/>
    დიზაინს და დეველოპმენტს</span></p>
    </div>
    <div className='col-xl-0.5'></div>
    <div className='col-xl-6' style={{fontFamily:'Nateli'}}><h5>ჩვენ ვიყენებთ React-ს, რაც არის ყველაზე მოთხოვნადი და მოწინავე ვებ-დეველოპმენტის front-end ფრეიმვორკი მსოფლიოში ამ მომენტისათვის.  
    თუ კლიენტს უნდა უფრო იაფი და დროში სწრაფი გადაწყვეტილება, Wordpress ხდება განხილვის საგანი. Wordpress არის ამჟამად  ყველაზე პოპულარული ტრადიციული დეველოპმენტ გადაწყვეტილებების იარაღი. ჩვენ უპრატესობას ვანიჭებთ Elementor-ს და WP Bakery ფეიჯ-ბილდერებს. </h5></div>
    </div>
    );
};

export default MeetGeo;