import React from 'react';
import NavBar from './NavBar'
import TypingDiscover from './TypingDiscover'
import './Discover.css'
import ReactGa from 'react-ga'
import {Helmet} from 'react-helmet'


const Discover = () => {
    ReactGa.initialize('UA-176397340-1');
  ReactGa.pageview('/discover')
    return (
        <div className='Discover container-fluid'>
        <Helmet>
        <title>Discover Reactor</title>
        <meta name="description" content="Reactor provides web-development services using React & Wordpress"/>
        <meta name="keywords" content="HTML, CSS, JavaScript, React, Front-end development, Wordpress, Elementor, WP Bakery, Unison, Woocommerce"/>
        <meta name="author" content="Niko Mchedlishvili"/>
     
        </Helmet>
        <NavBar/>
        <TypingDiscover/>
        </div>
    );
};

export default Discover;