import React from 'react';
import './All.css'
import ReactGa from 'react-ga'
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'

const All = () => {
    ReactGa.initialize('UA-176397340-1');
    ReactGa.pageview('/all')
    return (
        <div className='container-fluid'>

        <Helmet>
        <title>Reactor</title>
        <meta name="description" content="Reactor provides web-development services using React & Wordpress"/>
        <meta name="keywords" content="HTML, CSS, JavaScript, React, Front-end development, Wordpress, Elementor, WP Bakery, Unison, Woocommerce"/>
        <meta name="author" content="Niko Mchedlishvili"/>
     
        </Helmet>
        <div className="row All">
        <div className='col-lg-5'></div>
        <div className='col-lg-7 GenInfo'>
        <p><Link to='/'>Main Page</Link></p>
        <p><Link to='/services'>Services</Link></p>
        <p><Link to='/discover'>Discover Reactor</Link></p>
        </div>
        </div>
        </div>
    );
};

export default All;