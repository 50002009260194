import React from 'react';
import './All.css'
import ReactGa from 'react-ga'
import {Helmet} from 'react-helmet'
import { Link } from 'react-router-dom';

const All = () => {
    ReactGa.initialize('UA-176397340-1');
    ReactGa.pageview('/all/geo')

    return (
        <div className='container-fluid'>
        <Helmet>
        <title>რეაქტორი</title>
        <meta name="description" content="რეაქტორი გთავაზობთ ვებ-დეველოპმენტტს React-ის & Wordpress-ის გამოყენებით"/>
        <meta name="keywords" content="HTML, CSS, JavaScript, React, ვები, ვებ-საიტების დამზადება, ელექტრონული კომერცია, მარკეტინგი, Wordpress, Elementor, WP Bakery, Unison, Woocommerce"/>
        <meta name="author" content="ნიკო მჭედლიშვილი"/>
        </Helmet>

        <div className="row All">
        <div className='col-lg-5'></div>
        <div className='col-lg-7 GenInfo'>
        <p style={{fontFamily:'NinoBold'}}><Link to='/geo'>მთავარი გვერდი</Link></p>
        <p style={{fontFamily:'NinoBold'}}><Link to='/services/geo'>სერვისები</Link></p>
        <p style={{fontFamily:'NinoBold'}}><Link to='/discover/geo'>აღმოაჩინე 'რეაქტორი'</Link></p>
        </div>
        </div>
        </div>
    );
};

export default All;