import React from 'react';
import './Typing.css'
import RedBar from './RedBar'
import Bounce from 'react-reveal/Bounce';
import CombinedShape from './Assets/CombinedShape.png'
import Oval from './Assets/Oval.svg'

const TypingGeo = () => {
    return (
    
        <div className='Typing TypingGeo row'>
       <div className="col-lg-2 RedBarCol">
           <RedBar/>
           </div>
           <div className='col-lg-10 TextTyping TextTypingGeo' style={{top:'190px'}}>
           <Bounce left cascade>
           <p style={{fontFamily:'NinoCaps', fontSize:'24px'}}>ყველაფერი რაც გჭირდებათ ერთ კომპანიაში:</p>
           <p style={{fontFamily:'NinoBold', fontSize:'28px'}}>ვებ-გადაწყვეტილებები 'რეაქტში' & 'ვორდპრესში'</p>
           <p style={{fontFamily:'NinoCaps', fontSize:'24px'}}>ელექტრონული კომერცია, ვებ-დიზაინი, დეველოპმენტი,</p>
           <p style={{fontFamily:'NinoCaps', fontSize:'24px'}}>კონსულტაციები, დისტანციური სერვისები</p>
           </Bounce>
           <a href='#MovingCube'> <img src={CombinedShape}/></a>
           <img className='Oval' src={Oval}/>
           </div>
          </div>
        
    );
};

export default TypingGeo;